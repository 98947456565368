import { BASE_URL, STRIPE_API } from './constants';
import request from './request';


export async function paymentIntent(customer, id) {
  return await request(`${STRIPE_API}/intentsCreate.php`, {
    method: 'POST',
    body: JSON.stringify({
      customer:{customer},
      items: [{ id }],
    }),
  });
}

export async function grantCredit(price, credits, paymentUuid, userUid) {
  const headers = new Headers({
    'Content-Type': 'application/json',
    Authorization: '123456', // is this also the combination to your luggage?
  });

  const body = JSON.stringify({
    event: 'create',
    uuid: paymentUuid,
    uid: userUid,
    title: 'Granting ' + credits + ' Credits',
    body: 'This is an example of credits granted via webhook',
    amount: price,
    credits,
  });

  const requestOptions = {
    method: 'POST',
    headers,
    body,
    redirect: 'follow',
  };

  return await request(`${BASE_URL}/webhook-entities/listener`, requestOptions);
}

export async function updateCredit(credits, user) {
  const headers = new Headers({
    Accept: 'application/vnd.api+json',
    'Content-Type': 'application/vnd.api+json',
    'X-CSRF-Token': user.csrf_token,
    Authorization: `Bearer ${user.access_token}`,
  });

  const total = Number(user.field_credits) + Number(credits);

  const body = JSON.stringify({
    data: {
      type: 'user--user',
      id: user.uuid,
      attributes: {
        field_credits: total,
      },
    },
  });

  const requestOptions = {
    method: 'PATCH',
    headers,
    body
  };

  return await request(`${BASE_URL}/api/user/` + user.uuid, requestOptions);
}



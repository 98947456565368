/**
 * Modified from Josh Comeau: joshcomeau.com
 */
import { h } from 'preact';
import styled from '@emotion/styled/macro';
import {
  AtSign,
  ChevronDown,
  Circle,
  Compass,
  Crosshair,
  Disc,
  Edit3,
  Home,
  Heart,
  Key,
  Mail,
  MapPin,
  Minus,
  Plus,
  RotateCcw,
  Search,
  Star,
  Tag,
  User,
  X,
  XSquare,
  ArrowDown,
  ArrowDownCircle
} from 'react-feather';

const icons = {
  'at-sign': AtSign,
  'chevron-down': ChevronDown,
  circle: Circle,
  compass: Compass,
  crosshair: Crosshair,
  disc: Disc,
  edit: Edit3,
  home: Home,
  key: Key,
  mail: Mail,
  'map-pin': MapPin,
  minus: Minus,
  plus: Plus,
  rotate: RotateCcw,
  search: Search,
  star: Star,
  tag: Tag,
  user: User,
  'arrow-down-circle': ArrowDownCircle,
  'arrow-down': ArrowDown,
  heart: Heart,
  x: X,
  'x-square': XSquare,
};

const Icon = ({ id, size, strokeWidth = 1, ...delegated }) => {
  const Component = icons[id];

  if (!Component) {
    throw new Error(`No icon found for ID: ${id}`);
  }

  return (
    <Wrapper
      style={{
        '--size': size + 'px',
        '--stroke-width': strokeWidth + 'px',
      }}
      {...delegated}>
      <Component color='currentColor' size={size} fill="white" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: var(--size);
  height: var(--size);

  /*
    OMG I'm doing that thing I've warned against doing!
    Unfortunately, react-feather doesn't make it possible to pass
    discrete styles to the nested SVG within its components.

    Because I'm "reaching in" to third-party code, though, it feels
    OK. In my mind, this Icon is my bridge to that third-party code,
    and if I have to do some hacky stuff, that's fine. It's
    a special circumstance, and I won't ever have to look at the
    react-feather JSX and try to work out where this SVG style
    is coming from.
  */
  & > svg {
    display: block;
    stroke-width: var(--stroke-width);

  }
`;

export default Icon;

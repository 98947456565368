import styled from '@emotion/styled/macro';

const Text = styled.p`
  font-weight: ${props => (props.variant === 'light' ? 300 : 700)};
  text-transform: uppercase;
`;

export const SmallText = styled(Text)`
  font-size: calc(24 / 16 * 1rem);
`;

export const MediumText = styled(Text)`
  font-size: calc(35 / 16 * 1rem);
`;

export const LargeText = styled(Text)`
  font-size: calc(48 / 16 * 1rem);
`;

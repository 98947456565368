import { useState } from 'preact/hooks';
import styled from '@emotion/styled/macro';
import Dialog from '../Dialog';
import { FormGroup, FormGroupTitle, ValidationMessage } from '../Form';
import TextInput from '../TextInput';
import Checkbox from '../Checkbox';
import Button from '../Button';

const SignUp = ({register, showSignUp, setShowSignUp }) => {
  const [error, setError] = useState(null);
  const [checked, setChecked] = useState(1);

  const close = () => {
    setShowSignUp(false);
    setError(null);
  };

  const handleSubmit = async (id, event, callback) => {
    setError(null);
    const error = await callback(event);
    if (error) {
      console.error(error);
      setError({ id, message: error.message });
    } else close();
  };

  const handleRegister = event => {
    handleSubmit('sign-up', event, register);
  };

  return (
    <Dialog
      show={showSignUp}
      close={close}
      title='Sign Up'>
      <Wrapper>
        <Form id='sign-up' as='form' onSubmit={handleRegister}>
          <FormGroupTitle as='h2'>Sign Up</FormGroupTitle>
          {error?.id === 'sign-up' ? (
            <ValidationMessage>{error.message}</ValidationMessage>
          ) : null}
          <TextInput
            icon='user'
            label='username'
            name='username'
            placeholder='username'
            required='required'
          />
          <TextInput
            icon='mail'
            label='email'
            name='email'
            placeholder='email'
            required='required'
            type='email'
            pattern='\A[A-Z0-9+_.-]+@[A-Z0-9.-]+\Z'
          />
          <TextInput
            icon='key'
            label='password'
            name='password'
            placeholder='password'
            required='required'
            type='password'
          />
          <Checkbox
            label='Receive product announcements and new feature updates'
             value = {checked?1:0}
             name = 'newsletter'
             checked={checked}
             onChange={e => setChecked(e.target.checked)}

          />
          <Button variant='secondary' type='submit'>
            Sign Up
          </Button>
        </Form>
       
      </Wrapper>
    </Dialog>
  );
};

const Form = styled(FormGroup)`
  flex: auto;
  gap: var(--spacing-medium);
  width: 0;
  button {
    color: var(--white);
    font-weight: 700;
  }
`;

const Divider = styled.span`
  border-right: 1px solid var(--gray-300);
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;
`;

export default SignUp;

import { useState } from 'preact/hooks';
import styled from '@emotion/styled/macro';
import Dialog from '../Dialog';
import Button from '../Button';

const Welcome = ({showWelcome, setShowWelcome }) => {
  const [error, setError] = useState(null);

  
  const close = () => {
    setShowWelcome(false);
    setError(null);
  };

  const handleSubmit = async (id, event, callback) => {
    setError(null);
    const error = await callback(event);
    if (error) {
      console.error(error);
      setError({ id, message: error.message });
    } else close();
  };


  return (
    <Dialog
      show={showWelcome}
      close={close}
      title='Welcome'>
      <Wrapper>

        <Divider />

      </Wrapper>
    </Dialog>
  );
};


const Divider = styled.span`
  border-right: 1px solid var(--gray-300);
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;
`;

export default Welcome;

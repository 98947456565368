import { useEffect, useState } from 'preact/hooks';
import { Router } from 'preact-router';
import ReactGA from "react-ga";
import styled from '@emotion/styled/macro';
import { Global } from '@emotion/react';
import GlobalStyles from './GlobalStyles';
import Header from './Header';
import MapConfigurator from '../routes/MapConfigurator';
import MapPreview from '../routes/MapPreview';
// import Home from '../routes/Home';
import MyMaps from '../routes/Maps';
import Support from '../routes/Support';
import Showcase from '../routes/Showcase';
import Complete from '../routes/Complete';
import Products from '../routes/Products';
import Footer from './Footer';
import TagManager from 'react-gtm-module'

// styles for this kit
import "../assets/css/bootstrap.min.css";
import "../assets/scss/now-ui-kit.scss?v=1.5.0";
import 'mapbox-gl/dist/mapbox-gl.css';
import '@reach/dialog/styles.css';
import "@fontsource/roboto-slab"; // Defaults to weight 400.
import "@fontsource/roboto-slab/300.css"; // 300
import "@fontsource/roboto-slab/700.css"; // 700

import { useLocalStorageState } from '../hooks/useLocalStorage';
import {
  getLoginStatus,
  getUserInfo,
  loginUser,
  resetUser,
  logoutUser,
  registerUser,
  customerStripeID,
  getMaps
} from '../utils/userApi';
import { loadStripe } from '@stripe/stripe-js';

import { INIT_MAP } from '../utils/constants';

const tagManagerArgs = {
    gtmId: 'GTM-5NTL9KC'
}

if (typeof window !== 'undefined') {
  TagManager.initialize(tagManagerArgs)
  ReactGA.initialize('UA-207974373-1');
  ReactGA.ga('send', 'pageview', '/');
}


// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
//const stripePromise = loadStripe('pk_test_CEpiY0xpWRi73weE7bt3MkO6');
const stripePromise = loadStripe('pk_live_zo8JKuNg98OJCfxU4CHZKQq8');

const App = () => {
  const [user, setUser] = useLocalStorageState('user');
  const [showWelcome, setShowWelcome] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({
      "title": "Digital Map File",
      "field_product_width": "",
      "field_product_height": "",
      "field_size": "",
      "field_material": "",
      "field_price": "10.00",
      "field_product_front_image": "https://api.cartocuts.com//sites/default/files/styles/product/public/images/products/2022-06/Map-6229.png?itok=5-mG_IJR ",
      "field_product_back_image": "",
      "field_lifestyle_images": "",
      "field_type": "Digital",
      "field_stripe_id": "price_0L7OBKERgOuD5W8CjZjCynFV"});
  const [productType, setProductType] = useState("Digital");

  /** user map display options */
  const [customMarkers, setCustomMarkers] = useState(INIT_MAP.customMarker); // array of custom markers and labels to add to the map.
  const [labels, setLabels] = useState(INIT_MAP.labels); // array of toggled on labels
  const [layers, setLayers] = useState(INIT_MAP.layers); // array of toggled on layers
  const [mapSize, setMapSize] = useState(INIT_MAP.mapSize);
  const [mapStyle, setMapStyle] = useState(INIT_MAP.mapStyle); // The Mapbox style. A string url or a MapboxGL style object (regular JS object or Immutable.Map).
  const [mapStyleName, setMapStyleName] = useState(INIT_MAP.mapStyleName); // The name of the loaded mapbox style. Using this in combination with map.on('styledata') to listen for when we change map styles. Need this extra state because 'styledata' fires whenever a layer/label is toggled and we need to reset the layer/label checkboxes only when we change styles.
  const [mapTitle, setMapTitle] = useState(INIT_MAP.mapTitle);
  const [viewport, setViewport] = useState(INIT_MAP.viewport);
  const [userMaps, setUserMaps] = useState();
  const randomNumber = Math.floor(Math.random() * 10000);

  const login = async event => {
    event.preventDefault();
    // currently using built in html validation for bare minimum

    try {
      const { username, password } = event.target.elements;
      const user = await loginUser(username.value, password.value);
      const userInfo = await getUserInfo(user);
      ReactGA.event({
          category: 'User',
          action: 'Logged in'
      });
      setUser({
        ...user,
        ...userInfo[0],
      });
    } catch (error) {
      return error;
    }
  };

   const reset = async event => {
    event.preventDefault();
    // currently using built in html validation for bare minimum

    try {
      const { email } = event.target.elements
      ReactGA.event({
          category: 'User',
          action: 'Password Reset'
      });
      resetUser(email.value);
    } catch (error) {
      return error;
    }
  };

  const register = async event => {
    event.preventDefault();

    try {
      const { username, email, password, newsletter } = event.target.elements;
      const stripeId = await customerStripeID(username.value, email.value);
      const result = await registerUser(
        username.value,
        email.value,
        password.value,
        stripeId,
        newsletter.value
      );

      ReactGA.event({
          category: 'User',
          action: 'Created Account'
      });

      // double check we create a user successfully
      if (result.uuid) {
        // then login new user
        login(event);
      } else {
        throw Error('Unable to create new user');
      }
    } catch (error) {
      return error;
    }
  };

  const logout = async () => {
    try {
      await logoutUser(user);
      setUser(null);
    } catch (error) {
      console.error('Error in signOut user');
      return error;
    }
  };

  const updateUser = async () => {
    const status = await getLoginStatus(user);
    if (status === '1') {
      const userInfo = await getUserInfo(user);
      setUser(prevUser => ({
        ...prevUser,
        ...userInfo[0],
      }));
    } else {
      setUser(null);
      return;
    }
  };

  const resetMap = () => {
    setCustomMarkers(INIT_MAP.customMarker);
    setLabels(INIT_MAP.labels);
    setLayers(INIT_MAP.layers);
    setMapSize(INIT_MAP.mapSize);
    setMapStyle(INIT_MAP.mapStyle);
    setMapStyleName(INIT_MAP.mapStyleName);
    setMapTitle(INIT_MAP.mapTitle);
    setViewport(INIT_MAP.viewport);
  };

  /** If user found in local storage when app loads, check to see if auth is still valid */
  useEffect(() => {

    if (user) {
      updateUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** After user logs in, open payment modal if they don't have any credits */
  useEffect(() => {
    let timeout;

    // if (user && user?.field_credits == 0 ) {
      timeout = setTimeout(() => {
        //setShowWelcome(true);
      }, 3000);
    // }
    return () => clearTimeout(timeout);
  }, [user]);
  return (
    <div id='app'>
      <AppWrapper>
        <Global styles={GlobalStyles} />
        <Header
          login={login}
          logout={logout}
          register={register}
          reset={reset}
          showSignIn={showSignIn}
          setShowSignIn={setShowSignIn}
          showSignUp={showSignUp}
          setShowSignUp={setShowSignUp}
          showWelcome={showWelcome}
          setShowWelcome={setShowWelcome}
          showCheckout={showCheckout}
          setShowCheckout={setShowCheckout}
          stripePromise={stripePromise}
          updateUser={updateUser}
          user={user}
        />
        <MainContainer>
          <Router>
            <MapConfigurator
              path='/'
              customMarkers={customMarkers}
              setCustomMarkers={setCustomMarkers}
              labels={labels}
              setLabels={setLabels}
              layers={layers}
              setLayers={setLayers}
              mapSize={mapSize}
              setMapSize={setMapSize}
              mapStyle={mapStyle}
              setMapStyle={setMapStyle}
              mapStyleName={mapStyleName}
              setMapStyleName={setMapStyleName}
              mapTitle={mapTitle}
              setMapTitle={setMapTitle}
              viewport={viewport}
              setViewport={setViewport}
            />
            <MapPreview
              path='/preview'
              setShowSignIn={setShowSignIn}
              setShowSignUp={setShowSignUp}
              showCheckout={showCheckout}
              setShowCheckout={setShowCheckout}
              user={user}
              updateUser={updateUser}
              resetMap={resetMap}
              customMarkers={customMarkers}
              labels={labels}
              layers={layers}
              mapSize={mapSize}
              mapStyle={mapStyle}
              mapTitle={mapTitle ?(mapTitle):(`Map-${randomNumber}`)}
              setMapTitle={setMapTitle}
              viewport={viewport}
              selectProduct={selectedProduct}
              productType={productType}
            />
            <MyMaps
              path='/maps'
            />
            <Support
              path='/support'
            />
             <Showcase
              path='/showcase'
            />
             <Products
              path='/products'
              selectProduct={selectedProduct}
              setProduct={setSelectedProduct}
              updateProduct={setSelectedProduct}
              setProductType={setProductType}
            />
            <Complete
              path='/complete'
            />
          </Router>
        </MainContainer>
        <Footer />
      </AppWrapper>
    </div>
  );
};

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const MainContainer = styled.main`
  flex: 1;
`;

export default App;

import styled from '@emotion/styled/macro';
import Button from '../Button';
import Checkout from '../Checkout';
import { InputGroup } from '../Form';
import SignIn from '../SignIn';
import SignUp from '../SignUp';
import Welcome from '../Welcome';
import VisuallyHidden from '../VisuallyHidden';

import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';

const Header = ({
    user,
    updateUser,
    login,
    logout,
    register,
    reset,
    showSignIn,
    setShowSignIn,
    showSignUp,
    setShowSignUp,
    showWelcome,
    setShowWelcome,
    showCheckout,
    setShowCheckout,
    stripePromise,
}) => {
    return ( 
      <>
        <Wrapper>
        <nav class="navbar navbar-expand-sm bg-dark user-bar">
        <div className="buy-now">
          <Checkout
            promise={stripePromise}
            showCheckout={showCheckout}
            setShowCheckout={setShowCheckout}
            user={user}
            updateUser={updateUser}
            />
        </div>
          {user =="" ||user == null ? (
          <ul>
          <NavItem>
            <Button
              className="nav-link"
              color="secondary"
              id="login"
              onClick={() => setShowSignIn(true)}
            >
              <p>Login</p>
            </Button>
          </NavItem>
              <NavItem>
                <Button
                  className="nav-link"
                  color="secondary"
                  id="register"
                  onClick={() => setShowSignUp(true)}
                  >
                  <p>Sign Up</p>
                </Button>
              </NavItem>
              </ul>
              ):(
              <ul>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    Hi {user.name || 'friend'} 
                        </DropdownToggle>
                        <DropdownMenu right>
                        <DropdownItem>
                           {user.field_credits || 0} Downloads Remaining
                        </DropdownItem>
                        <DropdownItem divider />
                          <DropdownItem>
                            <Button><a href="/maps">My Maps</a></Button>
                          </DropdownItem>
                          <DropdownItem>
                            <Button onClick={logout}>Logout</Button>
                          </DropdownItem>

                        </DropdownMenu>
                      </UncontrolledDropdown>
                      </ul>
                  )}
        </nav>
        {/* <div class="main-nav"> */}
        {/*   <MainNavbar className="nav" /> */}
        {/* </div> */}
      </Wrapper>
      <Welcome showWelcome = { showWelcome } setShowWelcome = { setShowWelcome } />
      <SignIn
        login={login}
        reset={reset}
        showSignIn={showSignIn}
        setShowSignIn={setShowSignIn}
      />
       <SignUp register = { register } showSignUp = { showSignUp } setShowSignUp = { setShowSignUp } />
       </>
);
};



const Wrapper = styled.header `
  background-color: var(--primary-color);
`;
const TitleHeader = styled.div `
  align-items: center;
  display: flex;
  flex-grow: 0;
  font-weight: 700;
  justify-content: space-between;
  padding: 1.5rem 2rem;
`;

const SiteName = styled.h1 `
  line-height: 0;
  margin-bottom:0 !important;
`;

const Spacer = styled.span `
  flex-grow: 1;
`;

const Greeting = styled.div `
  flex-grow: 0;
 /* text-transform: capitalize;*/
`;


export default Header;
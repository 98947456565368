import { h } from 'preact';
import styled from '@emotion/styled/macro';

/**
 * styled checkbox adapted from Stephanie Eckles
 * https://moderncss.dev/pure-css-custom-checkbox-style/
 */
const Checkbox = ({ label, ...delegated }) => {
  return (
    <Wrapper>
      <InputWrapper>
        <HiddenInput type='checkbox' name={label} {...delegated} />
        <StyledCheckbox>
          <CheckMark
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            aria-hidden='true'
            focusable='false'>
            <path
              fill='none'
              stroke='currentColor'
              stroke-width='3'
              d='M1.73 12.91l6.37 6.37L22.79 4.59'
            />
          </CheckMark>
        </StyledCheckbox>
      </InputWrapper>
      <Label>{label}</Label>
    </Wrapper>
  );
};

const Wrapper = styled.label`
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 0.5em;
  font-size: calc(16 / 16 * 1rem);
  color: var(--color);
`;

const InputWrapper = styled.span`
  display: grid;
  grid-template-areas: 'checkbox';
  > * {
    grid-area: checkbox;
  }
`;

const HiddenInput = styled.input`
  opacity: 0;
  width: 1rem; /* retain width and height to ensure discoverability by users of touch devices. */
  height: 1rem;
  /** functionality gets attached to the hidden input with css */
  &:checked + span svg {
    transform: scale(1);
  }
  &:focus + span {
    box-shadow: 0 0 0 0.11em var(--white), 0 0 0 0.15em currentColor;
  }
  /* not spending too much time on this as not sure it will be needed.
  Currently the label does not show as faded when disabled */
  &:disabled + span {
    color: var(--gray-300);
  }
`;

const StyledCheckbox = styled.span`
  display: inline-grid;
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;
  border: 0.1em solid currentColor;
`;

const CheckMark = styled.svg`
  transition: transform 0.1s ease-out 25ms;
  transform: scale(0);
  transform-origin: bottom left;
`;

const Label = styled.span`
  line-height: 1;
`;

export default Checkbox;

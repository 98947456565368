import { BASE_URL } from './constants';
import request from './request';

// Create customer with stripe id
async function getStripeID(name, email) {
  return await request(`https://cartocuts.com/customerCreate.php?name=${encodeURIComponent(name)}&email=${encodeURIComponent(email)}`, {
    method: 'POST'
  });
}

export async function customerStripeID(name, email) {
  let result = await getStripeID(name, email);
  return result.value;
}

// Register a new user
export async function registerUser(name, email, password, stripeId, newsletter) {
  const headers = new Headers({
    'Content-Type': 'application/json'
  });

  const body = JSON.stringify({
    name: [{ value: name }],
    mail: [{ value: email }],
    pass: [{ value: password }], // todo: this needs to be encrypted (btoa)
    stripe_customer_id: [{ value: stripeId }],
    field_newsletter: [{ value: newsletter }]
  });

  const requestOptions = {
    method: 'POST',
    headers,
    body,
    redirect: 'follow'
  };

  return await request(
    `${BASE_URL}/user/register?_format=json`,
    requestOptions
  );
}

//Login an existing user
export async function loginUser(username, password) {
  const headers = new Headers({
    'Content-Type': 'text/plain'
  });
  const body = JSON.stringify({
    name: username,
    pass: password // todo: this needs to be encrypted (btoa)
  });

  const requestOptions = {
    method: 'POST',
    headers,
    body,
    redirect: 'follow'
  };

  return await request(`${BASE_URL}/user/login?_format=json`, requestOptions);
}


// Reset user password
export async function resetUser(email) {
  const headers = new Headers({
    'Content-Type': 'text/plain'
  });
  const body = JSON.stringify({
    mail: email,
  });

var requestOptions = {
  method: 'POST',
  headers: headers,
  body
};

  return await request(`${BASE_URL}/user/password?_format=json`, requestOptions);
}

/**
 * Check if the current user is logged in.
 * If user is logged in, they will have a cookie that will automatically
 * get sent with this request
 * @returns {0 | 1} logged in status (0 no | 1 yes)
 */
export async function getLoginStatus(user) {
  const headers = new Headers({
    'X-CSRF-Token': user.csrf_token,
    Authorization: `Bearer ${user.access_token}`
  });
  const requestOptions = {
    method: 'GET',
    headers,
    redirect: 'follow'
  };

  return await request(
    `${BASE_URL}/user/login_status?_format=json`,
    requestOptions
  );
}

/**
 * Get logged in user info
 * @param {object} user The logged in user
 * @returns {object} user information
 */
export async function getUserInfo(user) {
  const headers = new Headers({
    'X-CSRF-Token': user.csrf_token,
    Authorization: `Bearer ${user.access_token}`
  });
  const requestOptions = {
    method: 'GET',
    headers,
    redirect: 'follow'
  };

  return await request(`${BASE_URL}/user/me?_format=json`, requestOptions);
}

/**
 * log out the current user
 * @param {string} token
 */
export async function logoutUser(user) {
  const headers = new Headers({
    'content-type': 'application/json',
    'X-CSRF-Token': user.csrf_token
  });
  const requestOptions = {
    method: 'GET',
    headers
  };
  return await request(
    `${BASE_URL}/user/logout?_format=json&token=${user.logout_token}`,
    requestOptions
  );
}

/**
 * get number of downloads available to the user
 * @param {Integer} userId logged in user's uuid
 */
export async function getCredits(userId) {
  const headers = new Headers();
  const requestOptions = {
    method: 'GET',
    headers,
    redirect: 'follow'
  };

  const result = await request(
    `${BASE_URL}/api/user/${userId}`,
    requestOptions
  );
  console.log('Credits:' + result.data.attributes.field_user_credits);
  return result;
}

  // Get a list of users maps
//https://api.cartocuts.com/api/maps?_format=json

export async function getMaps(user) {

    var GetMapsHeaders = new Headers();
    GetMapsHeaders.append("Content-Type", "application/vnd.api+json");
    GetMapsHeaders.append("Accept", "application/vnd.api+json");
    GetMapsHeaders.append("Authorization", `Bearer ${user.access_token}`);

    var GetMapOptions = {
      method: 'GET',
      headers: GetMapsHeaders,
      redirect: 'follow',
    };

    const maps = fetch(`${BASE_URL}/api/maps/${user.uid}?_format=json`, GetMapOptions)
      .then(response => response.json())
      .then(result => result)

      return maps
  }


export const MAPBOX_TOKEN =
  'pk.eyJ1IjoidGhlY3V0YW5kZXRjaCIsImEiOiJja2VyaGE1amwxNWhuMnFuMTI5NW4xNGtsIn0.iewMUA5hOiI3Sfh0EZP2zQ';

export const MAP_STYLES = [
  {
    name: 'CartoStudio',
    style: 'mapbox://styles/thecutandetch/ckf1azt8v2qg219pnp3vpsner',
    // style: require('../../map_styles/cartocuts-studio.json'),
  },
  {
    name: 'CartoContrast',
    style: 'mapbox://styles/thecutandetch/ckf1fb8gn5i7x19piwkk9v81a',
  },
  {
    name: 'CartoMinimal',
    style: 'mapbox://styles/thecutandetch/ckerk2mb274by19nyga3tk0fo',
  },
  {
    name: 'CartoWH20',
    style: 'mapbox://styles/thecutandetch/ckerk1i3l4gli19pkh0zh904n',
  },
  {
    name: 'CartoContour',
    style: 'mapbox://styles/thecutandetch/ckerjwtx94b2h19qhvbgolk6c',
  },
  {
    name: 'CartoSwiss',
    style: 'mapbox://styles/thecutandetch/ckerjvacp4f4v19mxtiqcm54x',
  },
];
export const MAP_LAYERS = {
  LAYERS: [
    {
      name: 'contours',
      layers: ['contour_lines', 'contour_index_lines'],
    },
    {
      name: 'hillshade',
      layers: ['shadow1', 'shadow2'],
    },
    {
      name: 'waterways',
      layers: ['waterway_shadow'],
    },
    {
      name: 'trails',
      layers: ['trails', 'trail_bridges'],
    },
    {
      name: 'roads',
      layers: [
        'road_number_shield',
        'road_motorway_trunk',
        'road_motorway_trunk_case',
        'road_primary',
        'road_primary_case',
        'road_intersection',
        'road_secondary_tertiary',
        'road_secondary_tertiary_case',
        'bridge_primary_secondary_tertiary',
        'road_major_link',
        'road_street',
        'road_minor',
        'road_major_link_case',
        'road_secondary_tertiary_case',
        'road_street_case',
        'road_street_low',
        'road_minor_case',
        'road_minor_low',
      ],
    },
    {
      name: 'buildings',
      layers: ['building', 'building_outline'],
    },
  ],

  LABELS: [
    {
      name: 'peaks',
      layers: ['summit_label'],
    },
    {
      name: 'elevations',
      layers: ['elevation_labels', 'summit_elevation_label'],
    },
    {
      name: 'cities',
      layers: ['city_label', 'city_small_label'],
    },
    {
      name: 'suburbs',
      layers: ['suburb_label'],
    },
    {
      name: 'trails',
      layers: ['trail_labels'],
    },
    {
      name: 'roads',
      layers: ['road_label'],
    },
  ],
};

export const getMapStyle = name =>
  MAP_STYLES.find(style => style.name === name);

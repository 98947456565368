import { useEffect, useState } from 'preact/hooks';
import styled from '@emotion/styled/macro';
import { PRODUCTS } from './constants';

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {
  grantCredit,
  paymentIntent,
  updateCredit
} from '../../utils/paymentApi';
import ReactGA from "react-ga";
import Products from './Products';
import Processing from './Processing';
import Success from './Success';
import { FormGroup, ValidationMessage } from '../Form';

const initProduct = PRODUCTS[1];

const PaymentForm = ({ close, status, setStatus, user, updateUser }) => {
  const [disabled, setDisabled] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(initProduct);
  const [email, setEmail] = (user&&(useState(user.mail))||"anon@cartocuts.com");
  const elements = useElements();
  const stripe = useStripe();

  /**
   * On modal open, show error if no user logged in.
   * Otherwise, clear any previous status
   */
  useEffect(() => {
    console.log('[useEffect] in payment');
 
    if (!user) {
      ReactGA.event({
          category: 'User',
          action: 'Anonymous View Checkout'
      });
      setStatus({ error: 'You must be logged in to purchase downloads.' });
    } else {
      setStatus(null);
      ReactGA.event({
          category: 'User',
          action: 'Authenticated View Checkout'
      });
    }

    // only want this to run on first load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** update selected product 8*/
  const radioChange = event => {
    const product = PRODUCTS.find(product => product.id === event.target.id);
    setSelectedProduct(product);
  };

  /** credit card form validation */
  const handleChange = async event => {
    if (!user) {
      setDisabled(true);
      return;
    }
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    if (event.error) {
      setStatus({ error: event.error.message });
    } else {
      setStatus(null);
    }
  };

  /** make a purchase */
  const handleSubmit = async event => {
    event.preventDefault();
    // console.log(selectedProduct);

    if (user) {
      try {
        setDisabled(true);
        setStatus('processing');

        const data = await paymentIntent(user.stripe_customer_id, selectedProduct.code);
        if (!data.clientSecret) throw new Error({ error: 'There was a problem with the payment processor' });

        const payload = await stripe.confirmCardPayment(data.clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
          },
          receipt_email: email
        });

        if (payload.error) throw new Error({ error: `Payment failed ${payload.error.message}` });

        const grantCreditResponse = await grantCredit(
          selectedProduct.price,
          selectedProduct.credit,
          payload.paymentIntent.id,
          user.uid
        );

        const updateCreditResponse = await updateCredit(
          selectedProduct.credit,
          user
        );

        await updateUser();
        console.log(grantCreditResponse, updateCreditResponse);
        setStatus('success');
        ReactGA.event({
          category: 'Payment',
          action: 'Successful Payment'
        });

      } catch (error) {
        console.error(error);
        setStatus({ error });
        ReactGA.event({
          category: 'Payment',
          action: 'Failed Payment'
        });
      }
    }
  }

  return (
    <>
      <CenteredFormGroup>
        {status === 'processing' ? (
          <Processing />
        ) : status === 'success' ? (
          <Success close={close} selectedProduct={selectedProduct} />
        ) : (
          <Products
            radioChange={radioChange}
            selectedProduct={selectedProduct}
          />
        )}
      </CenteredFormGroup>
      <Stripe
        className={`stripe-payments ${(status === 'processing' || status === 'success') && 'hidden'
          }`}>
        <form id='payment-form' onSubmit={handleSubmit}>
          {/* <input */}
          {/*   type="text" */}
          {/*   value={email} */}
          {/*   onChange={(e) => setEmail(e.target.value)} */}
          {/*   placeholder="Enter email address" */}
          {/* /> */}
          <CardElement
            id='card-element'
            options={cardStyle}
            onChange={handleChange}
          />
          <button disabled={disabled} id='submit'>
            <span id='button-text'>Pay now</span>
          </button>
          {/* Show any error that happens when processing the payment */}
          {status?.error && (
            <ValidationMessage id='card-error'>
              {status.error}
            </ValidationMessage>
          )}
        </form>
      </Stripe>
    </>
  );
};

const CenteredFormGroup = styled(FormGroup)`
  align-items: center;
`;

/** FROM STRIPE */
const cardStyle = {
  style: {
    base: {
      color: 'var(--gray-900)',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: 'var(--gray-900)',
      },
    },
    invalid: {
      color: 'var(--secondary-color)',
      iconColor: 'var(--secondary-color)',
    },
  },
};
/** hammer in the stripe css as importing a stylesheet is causing problems */
const Stripe = styled(CenteredFormGroup)`
  form {
    width: min(100%, 520px);
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1),
      0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
  }

  input {
    border-radius: 6px;
    margin-bottom: 6px;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    font-size: 16px;
    width: 100%;
    background: white;
    box-sizing: border-box;
  }

  #card-error {
    line-height: 20px;
    margin-top: 12px;
    text-align: center;
  }

  #card-element {
    border-radius: 4px 4px 0 0;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    width: 100%;
    background: white;
    box-sizing: border-box;
  }

  #payment-request-button {
    margin-bottom: 32px;
  }

  /* Buttons and links */
  button {
    background: var(--secondary-color);
    font-family: Arial, sans-serif;
    color: var(--white);
    border-radius: 0 0 4px 4px;
    border: 0;
    padding: 10px 16px;
    font-size: calc(18 / 16 * 1rem);
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    text-transform: uppercase;
    width: 100%;
  }

  button:hover {
    filter: contrast(115%);
  }

  button:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &.hidden {
    display: none;
  }
`;

export default PaymentForm;

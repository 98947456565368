import styled from '@emotion/styled/macro';
import { PRODUCTS } from './constants';
import { InputGroup, InputGroupTitle } from '../Form';
import Radio from '../Radio';
import { SmallText } from '../Text';

const Products = ({ radioChange, selectedProduct }) => {
  return (
    <>
      <h3>High Resolution map downloads use one credit each to generate</h3>
 
      <SmallText>
        {selectedProduct.credit} Download
        {selectedProduct.credit > 1 ? 's' : ''} for ${selectedProduct.price}
      </SmallText>
      <VerticalGroup as='fieldset'>
        {PRODUCTS.map(product => (
          <Radio
            checked={product.id === selectedProduct.id}
            key={product.id}
            id={product.id}
            name='product-options'
            onClick={radioChange}
            value={product.price}>
            {product.credit} Download{product.credit > 1 ? 's' : ''} for $
            {product.price}
          </Radio>
        ))}
      </VerticalGroup>
  
    </>
  );
};

const VerticalGroup = styled(InputGroup)`
  align-items: flex-start;
  flex-direction: column;
  gap: var(--spacing-small);
  padding: 1rem 0;
`;

export default Products;

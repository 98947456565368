export const PRODUCTS = [
  {
    id: 'single',
    credit: 1,
    code: 'price_0JMI1VERgOuD5W8CxVQABC6p',
    price: 10,
  },
  {
    id: 'five',
    credit: 5,
    code: 'price_0HRt06ERgOuD5W8CDH4H8uUR',
    price: 45,
  },
  {
    id: 'ten',
    credit: 10,
    code: 'price_0HRsvMERgOuD5W8CdmsyWKAt',
    price: 80,
  },
  {
    id: 'twenty',
    credit: 20,
    code: 'price_0HRszMERgOuD5W8CgErDHOLK',
    price: 140,
  },
  {
    id: 'fifty',
    credit: 50,
    code: 'price_0HRt06ERgOuD5W8CDH4H8uUR',
    price: 250,
  }
];

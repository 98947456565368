import { useState } from 'preact/hooks';
import styled from '@emotion/styled/macro';
import Dialog from '../Dialog';
import { FormGroup, FormGroupTitle, ValidationMessage } from '../Form';
import TextInput from '../TextInput';
import Checkbox from '../Checkbox';
import Button from '../Button';

const SignIn = ({ login, reset, showSignIn, setShowSignIn }) => {
  const [error, setError] = useState(null);
  const [checked, setChecked] = useState(1);

  const close = () => {
    setShowSignIn(false);
    setError(null);
  };

  const handleSubmit = async (id, event, callback) => {
    //console.log(id,event,callback);
    setError(null);
    const error = await callback(event);
    if (error) {
      console.error(error);
      setError({ id, message: error.message });
    } else close();
  };

  const handleLogin = event => {
    handleSubmit('login', event, login);
  };

  const handleReset = event => {
    handleSubmit('reset', event, reset);
  };


  return (
    <Dialog
      show={showSignIn}
      close={close}
      title='Login'>
      <Wrapper>
        <Form id='login' as='form' onSubmit={handleLogin}>
          <FormGroupTitle as='h2'>Login</FormGroupTitle>
          {error?.id === 'login' ? (
            <ValidationMessage>{error.message}</ValidationMessage>
          ) : null}
          <TextInput
            icon='user'
            label='username'
            name='username'
            placeholder='username'
            required='required'
          />
          <TextInput
            icon='key'
            label='password'
            name='password'
            placeholder='password'
            required='required'
            type='password'
          />
          <Button variant='secondary' type='submit'>
            Login
          </Button>
        </Form>
        <Form id='reset' as='form' onSubmit={handleReset}>
          <FormGroupTitle as='h2'>Forgot Password?</FormGroupTitle>
            {error?.id === 'reset' ? (
            <ValidationMessage>{error.message}</ValidationMessage>
          ) : null}
          <TextInput
            icon='mail'
            label='email'
            name='email'
            placeholder='email'
            required='required'
          />
           <Button variant='secondary' type='submit'>
            Reset
          </Button>
        </Form>
      </Wrapper>
    </Dialog>
  );
};

const Form = styled(FormGroup)`
  flex: auto;
  gap: var(--spacing-medium);
  width: 0;
  button {
    color: var(--white);
    font-weight: 700;
  }
`;

const Divider = styled.span`
  border-right: 1px solid var(--gray-300);
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;
`;

export default SignIn;
